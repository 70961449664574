import { isProductionEnvironment } from '/@/conf'
import { type CurrentUser } from '/@/data/user/types'

const HUSHED = import.meta.env.VITE_APP_DEV_HUSH_TRACKING

interface BeaconEventInput {
  type: 'page-viewed'
  url: string
  title: string
}

declare global {
  interface Window {
    Beacon?: {
      (action: 'event', args: BeaconEventInput): Promise<void>
      (action: 'identify', args: { name: string; email: string }): Promise<void>
    }
  }
}

export function identifyBeacon(user: CurrentUser) {
  const args = {
    email: user.email,
    name: `${user.firstName} ${user.lastName}`,
  }
  if (!isProductionEnvironment) {
    if (HUSHED) return
    // eslint-disable-next-line no-console
    console.log('helpscout.event', 'identify', args)
  }

  if (!window.Beacon) {
    return
  }

  window.Beacon('identify', args)
}

export function sendBeaconPageViewed(url: string, title: string): void {
  if (!isProductionEnvironment) {
    if (HUSHED) return
    // eslint-disable-next-line no-console
    console.log('helpscout.event', 'page-viewed', url, title)
  }

  if (!window.Beacon) {
    return
  }

  window.Beacon('event', {
    type: 'page-viewed',
    url,
    title,
  })
}
