/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  defineComponent,
  defineAsyncComponent,
  h,
  reactive,
  toRefs,
  type UnwrapRef,
  onBeforeUnmount,
  watch,
} from 'vue'
import { useRoute } from 'vue-router'
import { throwError } from '../../errors'

const states = reactive<Record<string, { isOpen: boolean; data: unknown }>>({})
const initialDataMap = reactive<Record<string, unknown>>({})

export function checkLegacyModalState() {
  for (const s of Object.values(states)) {
    if (s.isOpen) return true
  }
  return false
}

export function useModal<T = any>(uid: string, initialData: T = {} as T) {
  if (!initialDataMap[uid]) initialDataMap[uid] = initialData

  let state = states[uid] as { isOpen: boolean; data: UnwrapRef<T> | T }
  if (!state) {
    state = states[uid] = reactive({
      isOpen: false,
      data: { ...initialData },
    })
  }

  function toggle(bool = !state.isOpen) {
    state.isOpen = bool
  }

  function show(opts: Partial<T> = {}) {
    for (const [k, v] of Object.entries(opts)) {
      if (!v) continue
      ;(state.data as T)[k as keyof T] = v as T[keyof T]
    }
    toggle(true)
  }

  function hide() {
    state.data = { ...(initialDataMap[uid] as T) }
    toggle(false)
  }

  return reactive({
    ...toRefs(state),
    uid,
    toggle,
    show,
    hide,
  })
}

export function createModal<T = any>(
  name: string,
  factory: () => Promise<any>,
  initialData: T = {} as T,
) {
  if (states[name]) {
    // note: allow hot reload of module in development
    if (import.meta.env.MODE !== 'development') {
      // we cannot create multiple modals we the same name
      throwError(
        `A modal with uid "${name}" already exists. Please choose a different uid."`,
      )
    }
  }

  const LazyModal = defineAsyncComponent(factory)
  const api = () => useModal<T>(name, initialData)
  const component = defineComponent({
    setup(_, { attrs }) {
      const modal = api()
      const route = useRoute()

      watch(
        () => route.path,
        () => {
          modal.hide()
        },
      )

      onBeforeUnmount(() => {
        modal.hide()
      })

      return () => (modal.isOpen ? h(LazyModal, attrs) : h(() => null))
    },
  })
  return { api, component }
}

export default defineAsyncComponent(
  () => import(/* webpackChunkName: "Modal" */ './Modal.vue'),
)
