import { reactive, ref } from 'vue'
import { type StellaIconId } from '/@/stella/icons/types'

interface State {
  active: boolean
  type: 'normal' | 'warning' | 'error'
  text: string
  onClick?: (() => void) | null
}

interface LegacyConfig {
  type?: 'legacy'
  text: string
  time?: number
  onClick?: (() => void) | null
}

interface LinkAction {
  type: 'link'
  link: string
}
interface ButtonAction {
  type: 'button'
  label?: string
  handler: () => void
}
interface StellaConfig {
  type: 'stella'
  title?: string
  message: string
  icon?: StellaIconId
  severity?: 'info' | 'warning' | 'critical' | 'success'
  action?: LinkAction | ButtonAction
  time?: number
}

type Config = LegacyConfig | StellaConfig

// pre-canned toasts
type PrecannedId = 'network-error'
const precanned = new Map<PrecannedId, StellaConfig>([
  [
    'network-error',
    {
      type: 'stella',
      severity: 'critical',
      message: `Oh no! Something has gone wrong. It looks like a network error. Try
          checking your connection and refreshing the page. If the problem still
          persists, please email support@spoak.com!`,
      time: 10_000,
    },
  ],
])

function create() {
  const legacyState = reactive<State>({
    active: false,
    type: 'normal',
    text: '',
    onClick: null,
  })
  const config = ref<StellaConfig | null>(null)
  let timer: ReturnType<typeof setTimeout> | null = null

  function close() {
    legacyState.onClick = null
    legacyState.active = false
    defuse()
  }

  function defuse() {
    if (timer) clearTimeout(timer)
    timer = null
  }

  function setTimer(time = 5000) {
    defuse()
    timer = setTimeout(() => {
      if (config.value) hide()
      else close()
    }, time)
  }

  function showLegacy(conf: LegacyConfig) {
    legacyState.text = conf.text
    legacyState.onClick = conf.onClick
    legacyState.type = 'normal'
    legacyState.active = true
    setTimer(conf.time)
  }
  function showWarning(conf: LegacyConfig) {
    legacyState.text = conf.text
    legacyState.onClick = conf.onClick
    legacyState.type = 'warning'
    legacyState.active = true
    setTimer(conf.time)
  }
  function showError(conf: LegacyConfig) {
    legacyState.text = conf.text
    legacyState.onClick = conf.onClick
    legacyState.type = 'error'
    legacyState.active = true
    setTimer(conf.time)
  }

  function show(conf: Config) {
    if (conf.type !== 'stella') {
      showLegacy(conf)
      return
    }

    config.value = conf

    if (conf.time || !conf.action) {
      setTimer(conf.time)
    }
  }
  function hide() {
    config.value = null
    defuse()
  }

  function showPrecanned(
    id: PrecannedId,
    overrides: Partial<
      Pick<StellaConfig, 'icon' | 'message' | 'title' | 'time'>
    > = {},
  ) {
    if (precanned.has(id)) {
      const config = precanned.get(id)!
      for (const [k, v] of Object.entries(overrides)) {
        if (!v) continue
        // @ts-ignore
        config[k] = v
      }
      show(config)
    }
  }

  return reactive({
    legacyState,
    config,
    show,
    showWarning,
    showError,
    close,
    hide,
    showPrecanned,
  })
}

let cachedApi: ReturnType<typeof create>

export function useToast() {
  if (!cachedApi) {
    cachedApi = create()
  }
  return cachedApi
}
