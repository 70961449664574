import { reactive, computed, toRefs } from 'vue'
import { identify as trackingIdentify } from '/@/core/tracking/api'
import { type CurrentUser } from './types'
import { useUserApi } from './api'
import { useRollbar } from '/@/core/rollbar'
import { clearExperimentFlags } from '/@/core/experiment'
import { identifyBeacon } from '/@/utils/helpscout'

interface State {
  accessToken: string | null
  loginIdentifier: string | null
  current: CurrentUser | null
}

// NOTE: this is the data module factory
function create() {
  const userApi = useUserApi()
  const rollbar = useRollbar()

  // The main state is a reactive object
  const state = reactive<State>({
    accessToken: null,
    loginIdentifier: null,
    current: null,
  })

  // we can add computed properties that run every time a state property used in the callback
  // changes
  const role = computed(() => {
    if (state.current?.isAdmin) return 'admin'
    if (!state.current) return 'anon'

    // TODO: role check based on profile
    return 'user'
  })

  function setAccessToken(accessToken: string) {
    state.accessToken = accessToken
  }

  function setLoginIdentifier(loginIdentifier: string) {
    state.loginIdentifier = loginIdentifier
  }

  const isLoggedIn = computed(() => {
    return Boolean(state.accessToken)
  })

  function clear() {
    state.current = null
    state.accessToken = null
    clearExperimentFlags()
  }

  async function refreshCurrentUser() {
    await fetchCurrentUser()
  }

  async function fetchCurrentUser() {
    const user = await userApi.fetch()

    if (user) {
      trackingIdentify(user)
      rollbar.configure(user)
      identifyBeacon(user)
    }

    state.current = user
  }

  return reactive({
    // NOTE: we explode the reactive object into singular refs, so that we can rewrap everything
    // into a new reactive object incorporating computed functions, and other functions
    ...toRefs(state),
    role,
    isLoggedIn,
    setAccessToken,
    setLoginIdentifier,
    fetchCurrentUser,
    refreshCurrentUser,
    clear,
  })
}

let cachedApi: ReturnType<typeof create>

export function useUser() {
  if (!cachedApi) {
    cachedApi = create()
  }
  return cachedApi
}
