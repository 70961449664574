/* eslint-disable @typescript-eslint/no-explicit-any */
import { type ApolloCache, type Reference } from '@apollo/client/cache'

interface Options {
  insertMode: 'prepend' | 'append'
}

export function deleteListItemFromCache<T>(
  id: string | undefined,
  cache: ApolloCache<any>,
  fieldName: keyof T,
  itemId: string,
) {
  cache.modify({
    id,
    fields: {
      [fieldName as string](itemRefs = [], { readField }) {
        return (itemRefs as Reference[]).filter(
          (ref: Reference) => readField('id', ref) !== itemId,
        )
      },
    },
  })
}

export function addListItemToCache<T>(
  id: string | undefined,
  cache: ApolloCache<any>,
  fieldName: keyof T,
  newItemRef: Reference,
  options: Options = { insertMode: 'append' },
) {
  cache.modify({
    id,
    fields: {
      [fieldName as string](itemRefs = [], { readField }) {
        if (
          (itemRefs as Reference[]).some(
            (ref: Reference) =>
              readField('id', ref) === readField('id', newItemRef),
          )
        ) {
          return itemRefs
        }

        if (options.insertMode === 'append') {
          return [...(itemRefs as Reference[]), newItemRef]
        } else {
          return [newItemRef, ...(itemRefs as Reference[])]
        }
      },
    },
  })
}

export function evictFromCache(
  cache: ApolloCache<any>,
  id: string,
  type: string,
  fieldName?: string,
) {
  const normalizedId = cache.identify({ id, __typename: type })

  if (normalizedId) {
    cache.evict({ id: normalizedId, fieldName })
    cache.gc()
  }
}
