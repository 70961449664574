/// <reference types="@types/segment-analytics" />

/* eslint-disable @typescript-eslint/naming-convention, @typescript-eslint/no-explicit-any */

import { computed, onBeforeUnmount, type ComputedRef } from 'vue'
import { type RouteLocationNormalized, useRoute } from 'vue-router'
import { type CurrentUser } from '/@/data/user/types'
import { getConfig, isProductionBuild, isProductionEnvironment } from '/@/conf'
import { device } from '/@/utils/responsive'
import * as amplitude from '@amplitude/analytics-browser'

const HUSHED = import.meta.env.VITE_APP_DEV_HUSH_TRACKING

const usingAmplitudeAnalytics = Boolean(getConfig().apiKeys.amplitude)
const usingSegmentAnalytics = Boolean(window.analytics)

declare global {
  interface Window {
    analytics: SegmentAnalytics.AnalyticsJS
    smartlook: any
    __init_smartlook: () => void
  }
}

function deviceType() {
  return device.isBelowSm ? 'mobile' : device.isAtLeastLg ? 'desktop' : 'tablet'
}

export function getAnonymousId() {
  if (usingAmplitudeAnalytics) return amplitude.getDeviceId()
  if (!window.analytics || !window.analytics.user) return
  return window.analytics?.user().anonymousId()
}

export let _pageLevelData: ComputedRef<Record<string, any>> = computed(
  () => ({}),
)
let _transitionalActionData: Record<string, any> | null = null

export function page(
  to: RouteLocationNormalized,
  from?: RouteLocationNormalized,
  extraProps: any = {},
) {
  // if we didn't set a meta.pageName then we don't want
  // to track that page
  if (!to.meta.pageName) return

  const name = to.meta.pageName as string

  const props = { ...extraProps, ...to.params }

  const query = new URL(window.location.href).searchParams
  const component = query.get('trackingComponent') || undefined
  let source = query.get('source') || undefined

  const configurationId = query.get('c_id') || undefined

  if (from && from.meta.pageName) {
    // @ts-ignore
    source = from.meta.pageId || from.meta.pageName
  }

  props.site = 'app'
  props.domain = window.location.hostname

  if (to.meta.pageId) {
    props.pageId = to.meta.pageId
  }

  if (to.name) props.routeName = to.name
  if (from?.name) props.fromRouteName = from.name
  if (source) props.source = source
  if (component) props.component = component
  if (configurationId) props.configurationId = configurationId
  if (document.body.classList.contains('pip')) {
    props.pip = true
  }

  const config = getConfig()
  if (config.version) props.version = config.version

  props.deviceType = deviceType()
  props.hasTouch = device.hasTouchCapability

  const finalProps = { ...props, ..._pageLevelData.value }

  if (!isProductionEnvironment) {
    if (HUSHED) return
    // eslint-disable-next-line no-console
    console.log('analytics.page', name, finalProps)
  }

  if (usingAmplitudeAnalytics)
    amplitude.track(`Viewed ${name} Page`, finalProps)

  if (usingSegmentAnalytics) window.analytics.page(name, finalProps)
}

export function identify(
  user: CurrentUser,
  extra: Record<string, string> = {},
) {
  const props = {
    ...extra,
    name: `${user.firstName} ${user.lastName}`,
    email: user.email,
  }

  if (!isProductionEnvironment) {
    if (HUSHED) return
    // eslint-disable-next-line
    console.log('analytics.identify', user.id, props)
  }

  if (usingAmplitudeAnalytics) {
    amplitude.setUserId(user.id)
    const identifyEvent = new amplitude.Identify()
    Object.entries(props).forEach(([key, value]) => {
      identifyEvent.set(key, value)
    })
    amplitude.identify(identifyEvent)
  }

  if (usingSegmentAnalytics) {
    window.analytics.identify(user.id, props)
  }

  if (isProductionBuild && isProductionEnvironment) {
    // We only want recorded sessions for logged in users
    if (!window.smartlook) {
      window.__init_smartlook()
    }

    window.smartlook('identify', user.id, {
      name: `${user.firstName} ${user.lastName}`,
      email: user.email,
    })
  }
}

export function identifyAnonymous(extra: Record<string, string> = {}) {
  const props = {
    ...extra,
  }

  if (!isProductionEnvironment) {
    // eslint-disable-next-line
    console.log('analytics.identify', 'anonymous', props)
  }

  if (usingAmplitudeAnalytics) {
    const identifyEvent = new amplitude.Identify()
    Object.entries(props).forEach(([key, value]) => {
      identifyEvent.set(key, value)
    })
    amplitude.identify(identifyEvent)
  }

  if (usingSegmentAnalytics) {
    window.analytics.identify(props)
  }
}

export function action(event: string, props?: any) {
  // cleaning `source: undefined` occurrences
  if (
    props &&
    {}.hasOwnProperty.call(props, 'source') &&
    props.source == null
  ) {
    delete props.source
  }

  if (document.body.classList.contains('pip')) {
    props.pip = true
  }

  const config = getConfig()
  if (config.version) props.version = config.version

  props.deviceType = deviceType()
  props.hasTouch = device.hasTouchCapability

  props.site = 'app'
  props.domain = window.location.hostname

  if (!isProductionEnvironment) {
    if (HUSHED) return
    // eslint-disable-next-line
    console.log('analytics.track', event, props)
  }

  if (usingAmplitudeAnalytics) {
    amplitude.track(event, props)
  }

  if (usingSegmentAnalytics) {
    window.analytics.track(event, props)
  }
}

export function useTracker() {
  const route = useRoute()

  function send(id: string, props?: any) {
    action(id, {
      ..._pageLevelData.value,
      ...props,
      ...route?.params,
      page: route.meta.pageName,
      parentUrl: route.query.parentUrl,
    })
  }

  function configurePageData(
    data: ComputedRef<Record<string, any>>,
    firePageEvent: boolean = false,
  ) {
    _pageLevelData = data
    if (firePageEvent) page(route)

    // note: never ever do this unless you're 100% sure of what you're doing!
    onBeforeUnmount(() => {
      _pageLevelData = computed(() => ({}))
    })
  }

  function setTransitionalActionData(data: Record<string, any> | null) {
    _transitionalActionData = data
  }
  function getTransitionalActionData() {
    const data = _transitionalActionData
    _transitionalActionData = null
    return data
  }

  return {
    send,
    identify,
    identifyAnonymous,
    configurePageData,
    setTransitionalActionData,
    getTransitionalActionData,
  }
}

export const actions = {
  // mockup tool
  sendFeedback(
    from: RouteLocationNormalized,
    rating: -2 | -1 | 0 | 1 | 2,
    reason?: string,
  ) {
    if (!reason) {
      action('Feedback Rating Clicked', {
        rating,
        type: 'Page',
        location: from.meta.pageName,
      })
      return
    }
    action('Feedback Details Submitted', {
      rating,
      type: 'Page',
      location: from.meta.pageName,
      reason,
    })
  },
}
