import {
  Experiment,
  ExperimentClient,
  type Exposure,
  type ExposureTrackingProvider,
} from '@amplitude/experiment-js-client'
import { useConfig } from '/@/core/config'
import { type Ref, ref, watchEffect, watch } from 'vue'
import {
  type ExternalFeatureId,
  type LocalFeatureId,
  isExternalFeature,
} from '/@/core/feature/types'
import { getLocalFeatureState } from '../feature'
import { useRollbar } from '/@/core/rollbar'

class SegmentExposureTrackingProvider implements ExposureTrackingProvider {
  private analytics: SegmentAnalytics.AnalyticsJS
  constructor(analytics: SegmentAnalytics.AnalyticsJS) {
    this.analytics = analytics
  }

  track(exposure: Exposure) {
    this.analytics.track('$exposure', exposure)
  }
}

export interface FeatureFlag {
  flagValue: Ref<boolean>
  loading: Ref<boolean>
}

interface VariantState {
  variant: Ref<string | null>
  loading: Ref<boolean>
}

let _experimentClient: ExperimentClient | undefined = undefined
const _variants = new Map<ExternalFeatureId, VariantState>()

export function clearExperimentFlags() {
  _variants.clear()
}

export function useExperiment(
  flag: ExternalFeatureId | LocalFeatureId,
  hasAutomaticExposure: boolean = true,
  shouldFetch?: Ref<boolean>,
) {
  const config = useConfig()
  const rollbar = useRollbar()

  const variant = ref<string | null>(null)
  const loading = ref(false)

  const usingAmplitudeAnalytics = Boolean(config.apiKeys.amplitude)

  function getOrCreateExperiment() {
    if (_experimentClient) return _experimentClient
    const deploymentKey = config.apiKeys.amplitudeExperiment

    if (!deploymentKey) {
      rollbar.error(`[Experiment] No deployment key found`)
      return
    }
    if (!usingAmplitudeAnalytics && !window.analytics) {
      rollbar.error(`[Experiment] No analytics provider found`)
      return
    }

    _experimentClient = usingAmplitudeAnalytics
      ? Experiment.initializeWithAmplitudeAnalytics(deploymentKey, {
          automaticExposureTracking: false,
        })
      : Experiment.initialize(deploymentKey, {
          exposureTrackingProvider: new SegmentExposureTrackingProvider(
            window.analytics,
          ),
          automaticExposureTracking: false,
        })

    return _experimentClient
  }

  async function fetchVariantImpl() {
    loading.value = true
    if (config.isLocal) {
      setTimeout(() => {
        const localFeatureState = getLocalFeatureState()
        variant.value = localFeatureState[flag] ? 'treatment' : 'control'
        loading.value = false
      }, 100)
    }

    try {
      if (!isExternalFeature(flag)) {
        loading.value = false
        return
      }
      const cachedVariant = _variants.get(flag)
      if (cachedVariant) {
        variant.value = cachedVariant.variant.value
        loading.value = cachedVariant.loading.value
        if (hasAutomaticExposure) {
          _experimentClient?.exposure(flag)
        }
        return
      }
      _variants.set(flag, { variant, loading })
      const experimentClient = getOrCreateExperiment()
      if (!experimentClient) {
        loading.value = false
        rollbar.error(`[Experiment] Unable to create experiment client`)
        return
      }

      let userInfo

      if (!usingAmplitudeAnalytics) {
        const user = window.analytics.user()
        const userId = user.id() ?? undefined
        const deviceId = user.anonymousId()
        userInfo = { user_id: userId, device_id: deviceId }
      }

      const clientWithVariants = await experimentClient.fetch(userInfo)

      if (!clientWithVariants) {
        loading.value = false
        rollbar.error(`[Experiment] Unable to fetch variants`)
        return
      }

      variant.value = clientWithVariants.variant(flag).value ?? null
      if (hasAutomaticExposure && variant.value) {
        experimentClient.exposure(flag)
      }
      loading.value = false
    } catch (e) {
      rollbar.error(`[Experiment] Exception starting the experiment`, e)
      loading.value = false
      return
    }
  }

  function trackExposureImpl() {
    const experimentClient = getOrCreateExperiment()
    if (!experimentClient) return
    experimentClient.exposure(flag)
  }

  async function fetchVariant() {
    if (usingAmplitudeAnalytics) {
      fetchVariantImpl()
      return
    }

    if (!window.analytics) return
    if (!window.analytics.user) window.analytics?.ready(fetchVariantImpl)
    else fetchVariantImpl()
  }

  function trackExposure() {
    if (usingAmplitudeAnalytics) {
      trackExposureImpl()
      return
    }

    if (!window.analytics) return
    if (!window.analytics.user) window.analytics?.ready(trackExposureImpl)
    else trackExposureImpl()
  }

  watchEffect(() => {
    if (!isExternalFeature(flag)) {
      return
    }
    const globalVariantState = _variants.get(flag)
    if (!globalVariantState) return
    variant.value = globalVariantState.variant.value
    loading.value = globalVariantState.loading.value
  })

  // fetch if no arg passed
  if (!shouldFetch) {
    fetchVariant()
  }

  watch(
    () => shouldFetch?.value,
    f => {
      if (!f) return
      fetchVariant()
    },
    {
      immediate: true,
    },
  )

  return { variant, loading, trackExposure }
}
