import { computed, reactive, readonly } from 'vue'
import { useRollbar } from '/@/core/rollbar'
import { useNavHeight } from '/@/stella/StellaNavBar'

export type DeviceSize = 'sm<' | 'sm' | 'md' | 'lg' | 'xl'

export function isTouchDevice() {
  return window.matchMedia('(any-pointer: coarse)').matches
}
export function isTouchOnlyDevice() {
  return window.matchMedia('(hover: none), (pointer: coarse)').matches
}
export function isSm() {
  return window.matchMedia('(max-width: 639px)').matches
}
export function isMd() {
  return window.matchMedia('(max-width: 767px)').matches
}
export function isLg() {
  return window.matchMedia('(max-width: 1023px)').matches
}
export function isXl() {
  return window.matchMedia('(max-width: 1279px)').matches
}
export function is2Xl() {
  return window.matchMedia('(max-width: 1535px)').matches
}
export function isPrint() {
  return window.matchMedia('print').matches
}

export function isAtLeastSm() {
  return window.matchMedia('(min-width: 640px)').matches
}
export function isAtLeastMd() {
  return window.matchMedia('(min-width: 768px)').matches
}
export function isAtLeastLg() {
  return window.matchMedia('(min-width: 1024px)').matches
}
export function isAtLeastXl() {
  return window.matchMedia('(min-width: 1280px)').matches
}
export function isBelowSm() {
  return window.matchMedia('(max-width: 639px)').matches
}
export function isBelowMd() {
  return window.matchMedia('(max-width: 767px)').matches
}
export function isBelowLg() {
  return window.matchMedia('(max-width: 1023px)').matches
}
export function isBelowXl() {
  return window.matchMedia('(max-width: 1279px)').matches
}
export function isBelow2Xl() {
  return window.matchMedia('(max-width: 1535px)').matches
}

const _device = reactive({
  isAtLeastSm: isAtLeastSm(),
  isAtLeastMd: isAtLeastMd(),
  isAtLeastLg: isAtLeastLg(),
  isAtLeastXl: isAtLeastXl(),
  isBelowSm: isBelowSm(),
  isBelowMd: isBelowMd(),
  isBelowLg: isBelowLg(),
  isBelowXl: isBelowXl(),
  isBelow2Xl: isBelow2Xl(),

  isPrint: isPrint(),
  hasTouchCapability: isTouchDevice(),
  hasOnlyTouchCapability: isTouchOnlyDevice(),

  // legacy (do not correctly reflect tailwind modus operandi, and lacks some flexibility)
  isTouch: isTouchDevice(),
  isSm: isSm(),
  isMd: isMd(),
  isLg: isLg(),
  isXl: isXl(),
  is2Xl: is2Xl(),
})

export const device = readonly(_device)

export function useDevice() {
  return _device
}

const rollbar = useRollbar()
try {
  const useDeprecatedListener = !window.matchMedia('').addEventListener

  if (useDeprecatedListener) {
    window.matchMedia('(any-pointer: coarse)').addListener(e => {
      _device.isTouch = e.matches
    })
    window.matchMedia('(max-width: 639px)').addListener(e => {
      _device.isSm = e.matches
      _device.isBelowSm = e.matches
    })
    window.matchMedia('(max-width: 767px)').addListener(e => {
      _device.isMd = e.matches
      _device.isBelowMd = e.matches
    })
    window.matchMedia('(max-width: 1023px)').addListener(e => {
      _device.isLg = e.matches
      _device.isBelowLg = e.matches
    })
    window.matchMedia('(max-width: 1279px)').addListener(e => {
      _device.isXl = e.matches
      _device.isBelowXl = e.matches
    })
    window.matchMedia('(max-width: 1535px)').addListener(e => {
      _device.is2Xl = e.matches
      _device.isBelow2Xl = e.matches
    })
    window.matchMedia('(min-width: 640px)').addListener(e => {
      _device.isAtLeastSm = e.matches
    })
    window.matchMedia('(min-width: 768px)').addListener(e => {
      _device.isAtLeastMd = e.matches
    })
    window.matchMedia('(min-width: 1024px)').addListener(e => {
      _device.isAtLeastLg = e.matches
    })
    window.matchMedia('(min-width: 1280px)').addListener(e => {
      _device.isAtLeastXl = e.matches
    })
  } else {
    window.matchMedia('(any-pointer: coarse)').addEventListener('change', e => {
      _device.isTouch = e.matches
    })
    window.matchMedia('(max-width: 639px)').addEventListener('change', e => {
      _device.isSm = e.matches
      _device.isBelowSm = e.matches
    })
    window.matchMedia('(max-width: 767px)').addEventListener('change', e => {
      _device.isMd = e.matches
      _device.isBelowMd = e.matches
    })
    window.matchMedia('(max-width: 1023px)').addEventListener('change', e => {
      _device.isLg = e.matches
      _device.isBelowLg = e.matches
    })
    window.matchMedia('(max-width: 1279px)').addEventListener('change', e => {
      _device.isXl = e.matches
      _device.isBelowXl = e.matches
    })
    window.matchMedia('(max-width: 1535px)').addEventListener('change', e => {
      _device.is2Xl = e.matches
      _device.isBelow2Xl = e.matches
    })
    window.matchMedia('(min-width: 640px)').addEventListener('change', e => {
      _device.isAtLeastSm = e.matches
    })
    window.matchMedia('(min-width: 768px)').addEventListener('change', e => {
      _device.isAtLeastMd = e.matches
    })
    window.matchMedia('(min-width: 1024px)').addEventListener('change', e => {
      _device.isAtLeastLg = e.matches
    })
    window.matchMedia('(min-width: 1280px)').addEventListener('change', e => {
      _device.isAtLeastXl = e.matches
    })
    window.matchMedia('print').addEventListener('change', e => {
      _device.isPrint = e.matches
    })
  }
} catch (e) {
  const params =
    e instanceof Error
      ? {
          jsErrorMessage: e.message,
          jsErrorStack: e.stack,
        }
      : {}
  rollbar.error('Something went wrong setting up responsive', params)
}

export function isLikelyIOS() {
  if (/iPad|iPhone|iPod/.test(navigator.platform)) {
    return true
  } else if (navigator.platform === 'MacIntel' && navigator.maxTouchPoints) {
    return navigator.maxTouchPoints
  } else {
    return false
  }
}

export function useDynamicHeight() {
  const navHeight = useNavHeight()

  const heightClass = computed(() => {
    switch (navHeight.displayType.value) {
      case 'default':
        return `h-regular`
      case 'default with top':
        return `h-regular-topbar`
      case 'tall':
        return `h-medium`
      case 'tall with top':
        return `h-medium-topbar`
      default:
        return 'h-full'
    }
  })

  return { heightClass }
}
