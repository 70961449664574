import { useToast } from '/@/core/elements/toast'
import { useRollbar } from '/@/core/rollbar'
import { reactive, toRefs } from 'vue'

interface State {
  active: boolean
  text: string
}

interface ShowConfig {
  text?: string
  delay?: number
  timeout?: number
  errorTitle?: string
  errorMessage?: string
}

const state = reactive<State>({
  active: false,
  text: '',
})
let timer: ReturnType<typeof setTimeout> | null = null
let timeout: ReturnType<typeof setTimeout> | number | null = null
function defuse() {
  if (timer) clearTimeout(timer)
  timer = null
}
function activate() {
  state.active = true
}

function setTimer(time = 50) {
  defuse()
  timer = setTimeout(activate, time)
}

function create() {
  const rollbar = useRollbar()
  const toast = useToast()

  function triggerTimeout(errorTitle: string, errorMessage?: string) {
    hide()
    toast.showPrecanned('network-error', {
      message: errorMessage,
    })
    // eslint-disable-next-line no-console
    console.log(errorTitle, errorMessage)
    rollbar.error(errorTitle, {
      errorMessage,
    })
  }
  function defuseTimeout() {
    if (timeout) clearTimeout(timeout as number)
    timeout = null
  }
  function setTimeoutTimer(conf: ShowConfig) {
    defuseTimeout()
    timeout = setTimeout(
      triggerTimeout,
      conf.timeout,
      conf.errorTitle,
      conf.errorMessage,
    )
  }

  function hide() {
    defuseTimeout()
    defuse()
    state.active = false
    state.text = ''
  }
  function show(conf: ShowConfig = {}) {
    state.text = conf.text ?? ''
    setTimer(conf.delay)
    if (conf.timeout) setTimeoutTimer(conf)
  }

  async function waitTask(fn: () => unknown, conf?: ShowConfig) {
    show(conf)
    try {
      await fn()
    } catch (e) {
      toast.showPrecanned('network-error', {
        message: conf?.errorMessage,
      })
      // eslint-disable-next-line no-console
      console.log(e)
      rollbar.error(conf?.errorTitle ?? 'Operation failed', {
        jsErrorMessage: (e as Error).message,
        jsErrorStack: (e as Error).stack,
      })
      hide()
      throw e
    } finally {
      hide()
    }
  }

  return reactive({
    ...toRefs(state),
    show,
    hide,
    waitTask,
  })
}

let cachedApi: ReturnType<typeof create>

export function useLoading() {
  if (!cachedApi) {
    cachedApi = create()
  }
  return cachedApi
}

// note: only to be used in the router to show/hide a loading screen between page navigation
export const __api = {
  hide() {
    defuse()
    state.active = false
    state.text = ''
  },
  show(conf: ShowConfig = {}) {
    state.text = conf.text ?? ''
    setTimer(conf.delay)
  },
}
