import { computed, reactive } from 'vue'
import { useDevice } from '/@/utils/responsive'

interface State {
  topbarVisible: boolean
  sidebarVisible: boolean
  leftNavVisible: boolean
}

function create() {
  return reactive<State>({
    topbarVisible: false,
    sidebarVisible: false,
    leftNavVisible: true,
  })
}

let cachedApi: ReturnType<typeof create>

export function useNavState() {
  if (!cachedApi) {
    cachedApi = create()
  }
  return cachedApi
}

export type StellaNavBarDisplayType =
  | 'default'
  | 'tall'
  | 'default with top'
  | 'tall with top'

export function useNavHeight() {
  const device = useDevice()
  const state = useNavState()

  const displayType = computed<StellaNavBarDisplayType>(() => {
    if (device.isMd) {
      if (state.topbarVisible) {
        return 'tall with top'
      } else {
        return 'tall'
      }
    } else {
      if (state.topbarVisible) {
        return 'default with top'
      } else {
        return 'default'
      }
    }
  })

  const totalHeight = computed<number>(() => {
    switch (displayType.value) {
      case 'default':
        return 60
      case 'default with top':
        return 100
      case 'tall':
        return 120
      case 'tall with top':
        return 160
      default:
        return 60
    }
  })

  return {
    displayType,
    totalHeight,
  }
}
