import { reactive, toRefs, computed } from 'vue'
import {
  type RouteLocationNormalized,
  useRoute,
  type NavType,
  type StellaNavType,
  type FooterType,
  type ThemeType,
  type NavThemeType,
} from 'vue-router'

interface State {
  previousRoute: RouteLocationNormalized | null
  currentRoute: RouteLocationNormalized | null
  loading: boolean
  theme: ThemeType
  footerType: FooterType
  navTheme: NavThemeType
  navType: NavType
  stellaNavType: StellaNavType
  fullWidth: boolean
}

function create() {
  const route = useRoute()

  const state = reactive<State>({
    previousRoute: null,
    currentRoute: null,
    loading: false,
    theme: 'default',
    footerType: 'none',
    navTheme: 'light',
    navType: 'none',
    stellaNavType: 'default',
    fullWidth: false,
  })

  const brandingEnabled = computed(() => Boolean(route.meta.hasBranding))

  return reactive({
    ...toRefs(state),
    brandingEnabled,
  })
}

let cachedApi: ReturnType<typeof create>

export function useAppState() {
  if (!cachedApi) {
    cachedApi = create()
  }
  return cachedApi
}
