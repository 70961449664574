import { DefaultApolloClient } from '/@/core/graphql'
import { inject } from 'vue'
import { ApolloClient, gql } from '@apollo/client/core'
import { type NormalizedCacheObject } from '@apollo/client/cache'
import { type CurrentUser } from './types'

export function useUserApi() {
  // FIXME: we may avoid doing the type cast if we create an InjectKey type for the apollo client
  // dependency somewhere in the main.ts
  const client = inject(
    DefaultApolloClient,
  ) as ApolloClient<NormalizedCacheObject>

  // current user
  async function fetch(): Promise<CurrentUser | null> {
    // NOTE: using plain apollo client here instead of useQuery hooks as we don't want the response
    // data to be buried in reactive wrappers. This data will later be incorporated in a reactive
    // state object, so this step here is superfluos.
    try {
      const { data, loading } = await client.query({
        query: gql`
          query getUser {
            user {
              id
              email
              firstName
              lastName
              isAdmin
              designerId
              designer {
                id
                status
                nickname
                branding {
                  backgroundColor
                  logoImageUrl
                  primaryColor
                  secondaryColor
                }
              }
              clientId
            }
          }
        `,
        fetchPolicy: 'network-only',
      })

      if (loading || !data) return null

      return data.user
    } catch {
      return null
    }
  }

  return { fetch }
}
