import { useAppState } from '/@/App/state'
import { useConfig } from '/@/core/config'
import { getAccessToken, getLoginIdentifier } from '/@/utils/cookie'
import { useUser } from '/@/data/user'

class AuthService {
  app = useAppState()
  config = useConfig()

  async checkSession(user: ReturnType<typeof useUser>, cookie: string) {
    if (user.isLoggedIn || this.config.env === 'cypress') return

    const accessToken = getAccessToken(cookie, this.config)
    const loginIdentifier = getLoginIdentifier(cookie, this.config)
    user.setAccessToken(accessToken)
    user.setLoginIdentifier(loginIdentifier)
    await user.fetchCurrentUser()
  }

  async logOut() {
    if (this.config.mode !== 'development') {
      window.location.href = this.config.urls.admin + '/logout'
    }
  }

  async logIn(user: ReturnType<typeof useUser>, cookie: string) {
    if (this.config.mode === 'development') {
      await this.checkSession(user, cookie)
    } else {
      const returnUrl =
        window.location.protocol !== 'https:'
          ? window.location.href.replace('http', 'https')
          : window.location.href
      window.location.href =
        this.config.urls.admin +
        '/login?returnUrl=' +
        encodeURIComponent(returnUrl)
    }
  }
}

function create() {
  return new AuthService()
}

let cachedApi: ReturnType<typeof create>

export function useAuth() {
  if (!cachedApi) {
    cachedApi = create()
  }
  return cachedApi
}
