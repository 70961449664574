import { type DefineComponent, h } from 'vue'
import { createModal } from '/@/core/elements/modal'
import type { StellaButtonType } from '/@/stella/types'

export interface Button {
  label: string
  action?: (() => unknown) | string
  type?: StellaButtonType
  class?: string
}

interface GenericModalDataWithStringContent {
  title: string
  content: string
  contentType: 'plain text' | 'html'
  btns: Button[]
  strict: boolean
  mode: 'simple' | 'classic'
}
interface GenericModalDataWithComponentContent {
  title: string
  // eslint-disable-next-line @typescript-eslint/ban-types, @typescript-eslint/no-explicit-any
  content: ReturnType<typeof h> | DefineComponent<{}, {}, any>
  contentType: 'component'
  btns: Button[]
  strict: boolean
  mode: 'simple' | 'classic'
}
export type GenericModalData =
  | GenericModalDataWithStringContent
  | GenericModalDataWithComponentContent

const { api, component } = createModal<GenericModalData>(
  'GenericModal',
  () => import('./GenericModal.vue'),
  {
    title: 'Info',
    content: '',
    contentType: 'plain text',
    btns: [],
    strict: false,
    mode: 'simple',
  },
)

export const useGenericModal = api
export default component
