/* eslint-disable @typescript-eslint/no-explicit-any */
import mitt, { type Handler } from '@meesayen/mitt'
import { useRollbar } from './rollbar'
import { useToast } from './elements/toast'

const emitter = mitt()

type EventType = 'error:viz' | 'error:generic'
interface ErrorData extends Event {
  msg: string
  extras: any
  toastMessage?: string
}

function listen(type: EventType, fn: Handler<ErrorData>) {
  emitter.on(type, fn as Handler<unknown>)
}

export function install() {
  const rollbar = useRollbar()
  const toast = useToast()

  listen('error:viz', e => {
    if (e.toastMessage) {
      toast.show({
        type: 'stella',
        severity: 'critical',
        message: e.toastMessage,
        time: 10_000,
      })
    }
    rollbar.error(e.msg, e.extras)
    // eslint-disable-next-line
    console.error(e)
  })
  listen('error:generic', e => {
    rollbar.error(e.msg, e.extras)
    // eslint-disable-next-line
    console.error(e)
  })
}

export function throwVizError(
  msg: string,
  extras?: any,
  toastMessage?: string,
) {
  emitter.emit('error:viz', { msg, extras: { ...extras }, toastMessage })
}

export function throwError(msg: string, extras?: any) {
  emitter.emit('error:generic', { msg, extras })
}
