import { inject } from 'vue'
import {
  ApolloClient,
  createHttpLink,
  type ServerError,
  type ServerParseError,
} from '@apollo/client/core'
import type { NormalizedCacheObject } from '@apollo/client/cache'
import { onError } from '@apollo/client/link/error'
import { getAccessToken, getLoginIdentifier } from '/@/utils/cookie'
import { useAuth } from '/@/core/auth'
import { useGenericModal } from '/@/core/modals/GenericModal'
import { useRollbar } from '/@/core/rollbar'
import type { Config } from '/@/core/config'
import { createCache } from './cache'
import { DefaultApolloClient } from './shims'
import { useLoading } from '../elements/loading'
import { toQueryString, getVendorParams } from '/@/utils/misc'
import { useToast } from '../elements/toast'
import { getAnonymousId } from '/@/core/tracking/api'
import { useRoute } from 'vue-router'

export * from './shims'
export { GraphqlCacheSymbol } from './cache'

function isServerError(
  networkError: Error | ServerError | ServerParseError | undefined,
): networkError is ServerError {
  if (!networkError) {
    return false
  } else if ('statusCode' in networkError) {
    return true
  } else {
    return false
  }
}

export function createGraphql(cookie: string, config: Config) {
  const token = getAccessToken(cookie, config)
  const loginIdentifier = getLoginIdentifier(cookie, config)
  const cache = createCache()
  const rollbar = useRollbar()
  const vendorParams = getVendorParams()
  let route = useRoute()

  const customFetch = (input: RequestInfo | URL, init?: RequestInit) => {
    if (!route) route = useRoute()

    let fetchUri = input
    let query = ''
    try {
      const queryString = toQueryString(vendorParams.value)
      if (queryString.length) query = `&${queryString}`
    } catch {
      // noop
    }

    query +=
      '&tracking.site=app&tracking.domain=' +
      encodeURIComponent(window.location.hostname)

    if (route) {
      query += `&tracking.page=${encodeURIComponent(route.meta?.pageName ?? route.name)}`
    }
    if (init?.body) {
      const { operationName } = JSON.parse(init.body as string)
      fetchUri = `${input}?o=${operationName}${query}`
    }

    if (init?.headers) {
      ;(init.headers as Record<string, string>)['Spoak-App-Version'] =
        config.version

      const trackingAnonymousId = getAnonymousId()
      if (trackingAnonymousId) {
        ;(init.headers as Record<string, string>)['Spoak-Anonymous-Id'] =
          trackingAnonymousId
      }
    }

    return fetch(fetchUri, init)
  }

  const headers: Record<string, string> = {
    Authorization: token ? `Bearer ${token}` : '',
  }
  if (loginIdentifier) headers['Spoak-Login-Identifier'] = loginIdentifier

  const httpLink = createHttpLink({
    uri: config.urls.gql,
    fetch: customFetch,
    headers,
  })

  const errorLink = onError(({ operation, networkError, graphQLErrors }) => {
    if (isServerError(networkError)) {
      if (networkError.statusCode === 401) {
        const auth = useAuth()
        const genericModal = useGenericModal()

        genericModal.show({
          title: 'Oops!',
          content:
            'It looks like your session is no longer active. Please log in again to continue.',
          btns: [
            {
              label: 'Log in',
              type: 'primary',
              async action() {
                await auth.logOut()
              },
            },
          ],
          strict: true,
        })
      } else if (networkError.statusCode === 409) {
        const auth = useAuth()
        const genericModal = useGenericModal()
        const loading = useLoading()

        // Hide the loading indicator (if present) to prevent it from blocking the modal
        loading.hide()

        genericModal.show({
          title: 'Oops!',
          content: `It looks like you're logged in on another device. To keep using Spoak on this device, log back in.`,
          btns: [
            {
              label: 'Log in',
              type: 'primary',
              async action() {
                await auth.logOut()
              },
            },
          ],
          strict: true,
        })
      } else {
        rollbar.error('Unexpected network error response', {
          query: operation.query,
          statusCode: networkError.statusCode,
          jsError: networkError.name,
          jsErrorMessage: networkError.message,
          jsErrorStack: networkError.stack,
        })
      }
    } else if (networkError) {
      rollbar.error('Unexpected network error', {
        query: operation.query,
        jsError: networkError.name,
        jsErrorMessage: networkError.message,
        jsErrorStack: networkError.stack,
      })
    } else if (graphQLErrors) {
      rollbar.error('GraphQL error', { query: operation.query, graphQLErrors })
    }
  })

  // Create the apollo client
  const client = new ApolloClient({
    link: errorLink.concat(httpLink),
    cache,
  })

  return { client, cache }
}

export function useGraphqlClient() {
  return inject(DefaultApolloClient) as ApolloClient<NormalizedCacheObject>
}

export interface Message {
  text: string
}
export function handleError(messagePrefix: string, resultMessage?: Message) {
  const toast = useToast()
  const errorMessage = `${messagePrefix}${resultMessage?.text ? ` ${resultMessage.text}` : ''}`
  toast.show({
    message: errorMessage,
    type: 'stella',
    severity: 'critical',
  })
}
