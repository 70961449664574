import { type Config } from '/@/core/config'

export function parse(cookie: string) {
  return Object.fromEntries(cookie.split(';').map(s => s.trim().split('=')))
}

export function getCookie(cookie: string, key: string) {
  if (!cookie) return null
  const map = parse(cookie)
  return map[key]
}

export function getAccessToken(cookie: string, config: Config) {
  if (config.mode === 'development' && config.apiKeys.spoak) {
    return `dev:${config.apiKeys.spoak}`
  }

  if (!cookie) return null

  const map = parse(cookie)

  if (config.env === 'prod') return map['sidt']

  return map['sidt_dev']
}

export function getLoginIdentifier(cookie: string, config: Config) {
  if (!cookie) return null

  const map = parse(cookie)

  if (config.env === 'prod') return map['slid']

  return map['slid_dev']
}
